import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {  
  obtenerTanques(customerID) {        
    var url = ConfigAPI.baseURL + "sensors/customers/"+ customerID + "/tanks" + Session.getToken();
    return instance.get(url); 
  },
  obtenerVinculacionTanque(data) {
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ data.id + Session.getToken();
    return instance.get(url); 
  },  
  filtrarDatosSensores(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sensors/customersTanks/reports/data" + Session.getToken();
    return instance.post(url, params); 
  },
  obtenerContactos(data) {
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ data.id + "/notifications" + Session.getToken();
    return instance.get(url);     
  },
  mostrarContactos(customerID, contactID) {
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ customerID + "/notifications/" + contactID + Session.getToken();
    return instance.get(url);     
  },    
  agregarContactos(customerTanksId, data) {
    var params = data
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ customerTanksId + "/notifications" + Session.getToken();
    return instance.post(url, params);     
  },
  editarContactos(customerTanksId, data) {
    var params = data
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ customerTanksId + "/notifications/" + data.id + Session.getToken();
    return instance.post(url, params);     
  },
  eliminarContacto(customerTanksId, data) {
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ customerTanksId + "/notifications/" + data.id + Session.getToken();
    return instance.delete(url);
  },
  agregarContactosGroup(customerTanksId, data) {
    var params = data
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ customerTanksId + "/notifications-group" + Session.getToken();
    return instance.post(url, params);     
  },
  editarContactosGroup(customerTanksId, contactID, data) {
    var params = data
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ customerTanksId + "/notifications-group/" + contactID + Session.getToken();
    return instance.post(url, params);     
  },

  obtenerTiposNotificaciones () {
    var url = ConfigAPI.baseURL + "notifications/type" + Session.getToken();
    return instance.get(url);   
  },
  obtenerUltimasAlertas (data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/lastAlerts" + Session.getToken();
    return instance.post(url, params);      
  },
  obtenerLastReport (data) {
    var params = data
    var url = ConfigAPI.baseURL + "sensors/customersTanks/reports/lastReport" + Session.getToken();
    return instance.post(url, params);   
  },
  editarVinculacionTanque(data, image1) {   
    var headers = {
      'Content-Type': 'multipart/form-data'
    }
    
    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
      
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },

  inactivarNotificaciones(id) {
    var params = {}
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ id + '/notification/inactive' + Session.getToken();
    return instance.post(url, params);   
  },  
  activarNotificaciones(id) {
    var params = {}
    var url = ConfigAPI.baseURL + "sensors/customersTanks/"+ id + '/notification/active' + Session.getToken();
    return instance.post(url, params);   
  },   
}

export default servicesAPI;
